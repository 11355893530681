<template>
  <section>
    <section class="top">
      <div>
        <p class="msg_title">
          Message
        </p>
        <p class="msg_subtitle">
          SMS Credit {{ smsBal ? smsBal : '000' }}
        </p>
      </div>
      <div>
        <div class="top_search">
          <VTextField
            v-model="search"
            :hide-details="true"
            label="Search Messages By Title"
            append-icon="search"
            solo
            flat
          />
        </div>
      </div>
      <div class="top_btn">
        <VBtn
          round
          large
          class="shadow"
          color="white"
          to="message/new"
        >
          New Message
        </VBtn>
      </div>
    </section>
    <section class="content">
      <div class="grid">
        <div
          v-for="sms in filteredSMS"
          :key="sms.id"
        >
          <VCard class="msg_card shadow">
            <VList two-line>
              <VListTile>
                <VListTileContent>
                  <VListTileTitle> {{ sms.data.title }} </VListTileTitle>
                  <VListTileSubTitle class="msg_main_txt">
                    {{ sms.data.type }} SMS
                  </VListTileSubTitle>
                </VListTileContent>
                <VListTileAction>
                  <VListTileActionText class="msg_main_txt">
                    {{ sms.data.date }}
                  </VListTileActionText>
                  <VBtn
                    icon
                    color="white"
                    @click="viewSMS(sms.id)"
                  >
                    <VIcon color="secondary">
                      visibility
                    </VIcon>
                  </VBtn>
                </VListTileAction>
              </VListTile>
            </VList>
          </VCard>
        </div>
      </div>
    </section>
    <div>
      <VDialog
        v-model="isLoading"
        width="300"
        persistent
      >
        <VCard
          v-if="loadStatus === 'loading'"
          class="notification_card elevation-20"
        >
          <p>Loading</p>
          <span>Fetching Data, Please Wait...</span>
          <div class="notification_ico">
            <VProgressCircular
              indeterminate
              color="primary"
              :size="30"
              :width="3"
            />
          </div>
        </VCard>
        <VCard
          v-if="loadStatus === 'netfail'"
          class="notification_card elevation-20"
        >
          <p>Internet Issue</p>
          <span>Retrying In {{ retry }} Seconds</span>
          <div class="notification_ico">
            <VProgressCircular
              indeterminate
              color="red"
              :size="30"
              :width="3"
            />
          </div>
        </VCard>
        <VCard
          v-if="loadStatus === 'fail'"
          class="notification_card elevation-20"
        >
          <p>Error</p>
          <span>An Error Occured</span>
          <div class="notification_ico">
            <VBtn
              depressed
              round
              color="primary"
              @click="reloadPage()"
            >
              Reload
            </VBtn>
          </div>
        </VCard>
      </VDialog>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import api from '../../conf/api';


export default {
  data: () => ({
    search: '',
    smsList: [],
    isLoading: true,
    loadStatus: '',
    retry: 0,
    smsBal: '',
  }),
  computed: {
    filteredSMS() {
      return this.smsList.filter(sms => sms.data.title.toLowerCase()
        .match(this.search.toLowerCase()));
    },
  },
  async beforeMount() {
    try {
      this.loadStatus = 'loading';
      await axios.get(`${api}/sms/bal`)
        .then((res) => {
          if (res.data !== '') {
            this.smsBal = res.data.balance;
          }
        });
      await axios.get(`${api}/sms/all`)
        .then((res) => {
          this.isLoading = false;
          if (res.data !== '') {
            this.smsList = res.data.data;
          }
        });
    } catch (e) {
      if (e.code === 'auth/network-request-failed') {
        this.loadStatus = 'netfail';
        this.retry = 10;
        setInterval(() => {
          this.retry -= 1;
        }, 1000);
        setTimeout(() => {
          window.location.reload();
        }, 9500);
      } else {
        this.loadStatus = 'fail';
      }
    }
  },
  methods: {
    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 200);
    },
    viewSMS(id) {
      this.$router.push(`/message/${id}`);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../../assets/stylus/Message/index';
</style>
